<template>
  <el-dialog :close-on-click-modal="false" v-model="dialogVisible" title="AI自动补充配置" width="835px">
    <el-scrollbar :maxHeight="contentStyleObj" v-loading="loading">
      <div v-if="type != 'bank' && hintStr" class="hint">
        <div class="hint_title">专属AI记账模型已生成</div>
        <div class="hint_content">
          <el-icon>
            <CircleCheck />
          </el-icon><span>
            {{ hintStr }}&nbsp;<span class="hint_set" @click="setHy">设置</span>
          </span>
        </div>
      </div>
      <div v-if="!hintStr && type != 'bank'" class="hint_btn">
        <div>
          <el-button size="small" type="primary" @click="setHy" plain>设置行业</el-button>
        </div>
        <buttonCommon name="生成AI记账模型" @success="setMx()" :comId="comId" taskName="tax-cj-outInvoice" period="197001"
          @fresh="getInfo"></buttonCommon>
      </div>
      <div style="margin-bottom: 10px">
        <el-alert title="注意：不勾选默认全部自动补充，自动补充会同时清除关联凭证信息" type="warning" effect="dark" show-icon :closable="false" />
      </div>
      <el-form :model="form" label-width="140px" size="small" :inline="true">
        <!-- <div>
          <div class="record" v-if="type != 'bank' && !hintStr">类型行业</div>
          <el-form-item label="公司业务类型:" v-if="type != 'bank' && !hintStr">
            <el-radio-group v-model="form.ywlx" @change="changeZdzg">
              <el-radio :label="0">服务类</el-radio>
              <el-radio :label="1">贸易类</el-radio>
              <el-radio :label="2">生产类</el-radio>
              <el-radio :label="3">工程类</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="特殊行业:" v-if="type != 'bank'">
            <select-common v-model:modelValue="form.tshy" :options="tshyOptions" style="width: 154px; margin: 0" />
          </el-form-item>
        </div> -->
        <div class="record">{{ type == "bank" ? "银行补充" : "发票补充" }}</div>
        <div>
          <el-form-item v-if="type != 'bank'" label="进项往来科目:">
            <el-select v-model="form.jxjsfs" style="width: 154px; margin: 0">
              <el-option label="应付账款" :value="0"></el-option>
              <el-option label="预付账款" :value="1"></el-option>
              <el-option label="其他应付款" :value="2"></el-option>
              <el-option label="现金结算" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="type != 'bank'" label="销项往来科目:">
            <el-select v-model="form.xxjsfs" style="width: 154px; margin: 0">
              <el-option label="应收账款" :value="0"></el-option>
              <el-option label="预收账款" :value="1"></el-option>
              <el-option label="其他应收款" :value="2"></el-option>
              <el-option label="现金结算" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <div v-if="type != 'bank'">
            <el-form-item label="进项小额结算阈值:">
              <el-input v-model="form.xjjsfz" style="width: 154px"></el-input>
            </el-form-item>
            <el-form-item label="进项小额结算科目:">
              <el-select v-model="form.xjjskm" style="width: 154px; margin: 0">
                <el-option label="现金" :value="0"></el-option>
                <el-option label="其他应付款-小额支出" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="销项小额结算阈值:">
              <el-input v-model="form.xjjsfzOut" style="width: 154px"></el-input>
            </el-form-item>
            <el-form-item label="销项小额结算科目:">
              <el-select v-model="form.xjjskmOut" style="width: 154px; margin: 0">
                <el-option label="现金" :value="0"></el-option>
                <el-option label="其他应收款-小额收入" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="存货补充:" v-if="type != 'bank'">
            <el-radio-group v-model="form.chbc">
              <el-radio :label="0">{{ filterText(form.ywlx) }}-商品分类<span style="color: red">（推荐）</span></el-radio>
              <el-radio :label="1" v-if="checkDisabled">{{ filterText(form.ywlx) }}-商品名称</el-radio>
              <el-radio :label="2" v-if="checkDisabled">{{ filterText(form.ywlx) }}-商品分类-商品名称
                （复杂）</el-radio>
              <el-radio :label="3" v-if="checkDisabled">{{ filterText(form.ywlx, "1") }}（简单）</el-radio>
              <el-radio :label="4" v-if="checkDisabled">{{
                filterText(form.ywlx)
                }}（商品分类辅助核算）</el-radio>
              <el-radio :label="5" v-if="checkDisabled">{{ filterText(form.ywlx) }}-（商品名称辅助核算）</el-radio>
              <el-radio :label="6" v-if="checkDisabled">{{ filterText(form.ywlx) }}（极简）</el-radio>
              <span class="set_style" @click="elCheck">{{
                checkDisabled ? "简易设置" : "高级设置"
              }}</span>
            </el-radio-group>
          </el-form-item>
          <div v-if="[1, 2, 4, 5].includes(form.chbc) && type != 'bank'">
            <el-form-item label="存货是否使用型号:">
              <el-radio-group v-model="form.chbcSpec">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
        <div>
          <el-form-item v-if="type == 'bank'" label="进项对冲科目:">
            <el-select v-model="form.jxyhjsfs" style="width: 154px; margin: 0">
              <el-option label="应付账款" :value="0"></el-option>
              <el-option label="预付账款" :value="1"></el-option>
              <el-option label="其他应付款" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="type == 'bank'" label="销项对冲科目:">
            <el-select v-model="form.xxyhjsfs" style="width: 154px; margin: 0">
              <el-option label="应收账款" :value="0"></el-option>
              <el-option label="预收账款" :value="1"></el-option>
              <el-option label="其他应收款" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="往来结算科目:" v-if="type == 'bank'">
            <el-radio-group v-model="form.bankGr">
              <el-radio :label="0">其他应收/应付账款-个人姓名</el-radio>
              <el-radio :label="1">其他应收/应付账款-个人</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="save" >确定</el-button>
      </span>
    </template>
  </el-dialog>
  <setSshy ref="setSshy" @success="getInfo" />
</template>

<script>
import { companyAutomaticSetList, invoiceMx } from "@/api/automatic"
import { autoInInvoice, autoOutInvoice } from "@/api/invoice"
import { autoBank } from "@/api/bank"
import { SPECIAL_INDUSTRY_OPTION } from '@/utils/commonData'
import setSshy from './setSshy.vue'
export default {
  name: "",
  components: {
    setSshy
  },
  props: {},
  data() {
    return {
      dialogVisible: false,
      contentStyleObj: {},
      comId: null,
      form: {
        sblx: 0,
        ywlx: 0,
        gx: 0,
        yh: 0,
        cjqr: 0,
        jxjsfs: 0,
        xxjsfs: 0,
        xjjsfz: 0,
        xjjsfzOut: 0,
        skqr: 0,
        skqrfz: 0,
        chbc: 0,
        myhsBl: 0,
        zj_not_zero: 0,
        zjqr: 0,
        hslx: 0,
        zdzgsz: 0,
        djblsz: 0,
        djbl: 0,
        zgbl: 0,
        tshy: "",
        bankGr: 0,
        scmll: 0,
        chbcSpec: 0,
        jxyhjsfs: 0,
        xxyhjsfs: 0,
        sbshebao: 0,
        jdcj: 0,
        jdcjBank: 0,
      },
      comIds: [],
      loading: false,
      qrStatus: false,
      checkDisabled: false,
      type: "",
      bcIds: [],
      hintStr: "",
      tshyOptions: SPECIAL_INDUSTRY_OPTION,
      period: this.$store.getters["user/comInfo"].period
    }
  },
  mounted() {
    this.contentStyleObj = this.$getHeight(250)
  },
  methods: {
    init(ids, type, arr) {
      this.dialogVisible = true
      this.type = type ? type : ""
      this.bcIds = arr ? arr : []
      this.reset()
      this.comIds = ids
      if (ids.length == 1) {
        this.comId = ids[0]
        this.getInfo()
      }
    },
    getInfo() {
      this.loading = true
      companyAutomaticSetList({ comId: this.comId }).then((res) => {
        this.loading = false
        if (res.data.msg == "success" && res.data.data.list.id) {
          this.form = res.data.data.list
          if (this.form.chbc == 0) {
            this.checkDisabled = false
          } else {
            this.checkDisabled = true
          }
        }
        this.hintStr = res.data.data.str
        if (this.hintStr) {
          let str = this.hintStr.slice(-4)
          const keywordMap = {
            '服务': 0,
            '贸易': 1,
            '生产': 2,
            '工程': 3
          };
          for (const [keyword, value] of Object.entries(keywordMap)) {
            if (str.includes(keyword)) {
              this.form.ywlx = value;
              break;
            }
          }
        }
      })
    },
    reset() {
      this.form = {
        sblx: 0,
        ywlx: 0,
        gx: 0,
        yh: 0,
        cjqr: 0,
        jxjsfs: 0,
        xxjsfs: 0,
        xjjsfz: 0,
        xjjsfzOut: 0,
        skqr: 0,
        skqrfz: 0,
        chbc: 0,
        myhsBl: 0,
        zj_not_zero: 0,
        zjqr: 0,
        hslx: 0,
        zdzgsz: 0,
        djblsz: 0,
        djbl: 0,
        zgbl: 0,
        bankGr: 0,
        scmll: 0,
        chbcSpec: 0,
        jxyhjsfs: 0,
        xxyhjsfs: 0,
        sbshebao: 0,
        jdcj: 0,
        jdcjBank: 0,
      }
    },
    elCheck() {
      this.checkDisabled = !this.checkDisabled
    },
    setMx() {
      invoiceMx([{ comId: this.comId }]).then(res => {
        if (res.data.msg == 'success') {
          this.$qzfMessage('任务已发起')
          this.getInfo()
        }
      })
    },
    save() {
      if(!this.hintStr && this.type != 'bank'){
        this.$qzfMessage("检测到该公司未生成模型，请先生成模型或设置行业后再进行自动补充操作", 2)
        return
      }
      if (isNaN(Number(this.form.xjjsfz))) {
        this.$qzfMessage("请输入正确的进项小额结算阀值", 1)
        return
      }
      if (isNaN(Number(this.form.xjjsfzOut))) {
        this.$qzfMessage("请输入正确的销项小额结算阀值", 1)
        return
      }
      if (isNaN(Number(this.form.skqrfz))) {
        this.$qzfMessage("请输入正确的税款阈值", 1)
        return
      }
      if (isNaN(Number(this.form.myhsBl))) {
        this.$qzfMessage("请输入正确的贸易核算比例", 1)
        return
      }
      if (isNaN(Number(this.form.djbl))) {
        this.$qzfMessage("请输入正确的单价比例", 1)
        return
      }
      if (isNaN(Number(this.form.scmll))) {
        this.$qzfMessage("请输入正确的生产比例", 1)
        return
      }
      this.form.myhsBl = Number(this.form.myhsBl)
      this.form.xjjsfz = Number(this.form.xjjsfz)
      this.form.xjjsfzOut = Number(this.form.xjjsfzOut)
      this.form.skqrfz = Number(this.form.skqrfz)
      this.form.djbl = Number(this.form.djbl)
      this.form.scmll = Number(this.form.scmll)
      if (this.type == "in") {
        this.dialogVisible = false
        this.$emit("success2")
        let param = {
          setting: this.form,
          ids: this.bcIds,
        }
        autoInInvoice(param).then((res) => {
          // 发起超过一定量时间就报跟发票类似的
          if (res.data.msg == "success") {
            if (res.data.data.msg != "") {
              this.$qzfMessage(res.data.data.msg)
            } else {
              this.$qzfMessage("进项补充成功")
            }
          }
          this.$bus.emit("autoBcStatusUpdate")
          this.$emit("success")
        })
      } else if (this.type == "out") {
        this.dialogVisible = false
        this.$emit("success2")
        let param = {
          setting: this.form,
          ids: this.bcIds,
        }
        autoOutInvoice(param).then((res) => {
          if (res.data.msg == "success") {
            if (res.data.data.msg != "") {
              this.$qzfMessage(res.data.data.msg)
            } else {
              this.$qzfMessage("销项补充成功")
            }
          }
          this.$bus.emit("autoBcStatusUpdate")
          this.$emit("success")
        })
      } else if (this.type == "bank") {
        this.dialogVisible = false
        this.$emit("success2")
        let param = {
          setting: this.form,
          ids: this.bcIds,
        }
        autoBank(param).then((res) => {
          if (res.data.msg == "success") {
            if (res.data.data.msg != "") {
              this.$qzfMessage(res.data.data.msg)
            } else {
              this.$qzfMessage("银行补充成功")
            }
          }
          this.$bus.emit("autoBcStatusUpdate")
          this.$emit("success")
        })
      }
    },
    changeZdzg() {
      if (this.form.myhsBl != 0) {
        this.form.zdzgsz = 1
      } else {
        this.form.zdzgsz = 0
      }
    },
    filterText(type, flag) {
      if (type == 2) {
        return "原材料" + (flag ? "-原材料" : "")
      } else if (type == 3) {
        return "工程物资" + (flag ? "-工程物资" : "")
      } else {
        return "库存商品" + (flag ? "-库存商品" : "")
      }
    },
    setHy() {
      this.$refs.setSshy.init(this.comId)
    },
  },
}
</script>

<style scoped lang="scss">
.record {
  font-size: 14px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
}

.record::before {
  content: "";
  background: var(--themeColor, #17a2b8);
  display: inline-block;
  width: 3px;
  height: 13px;
  position: relative;
  margin-right: 10px;
}


:deep(.el-alert__title) {
  letter-spacing: 1px;
}

.hint {
  background-image: url("../../../assets/aimodal.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  padding: 15px 35px;
}

.hint_title {
  font-size: 26px;
  font-weight: 700;
  background-image: -webkit-linear-gradient(left, #000, #0a5baa, #0f39a5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-bottom: 5px;
  border-bottom: 1px solid #4279b1;
  letter-spacing: 3px;
  display: inline-block;
}

.hint_content {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #3c3c3d;

  span {
    margin-left: 5px;
  }
}

.set_style {
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  color: var(--themeColor, #17a2b8);
  margin-top: 2px;
}

:deep(.el-alert--warning.is-dark) {
  background-color: #304456 !important;
}

.hint_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.hint_set {
  text-decoration: underline;
  cursor: pointer;
}
</style>
