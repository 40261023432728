<template>
  <el-dialog :close-on-click-modal="false" v-model="dialogVisible" title="设置行业" width="500px" append-to-body>
    <el-form :model="form" size="small">
      <el-form-item label="行业大类:">
        <el-select v-model="form.sshyBig" placeholder="请选择行业大类" clearable @change="changeBig" filterable class="w-250">
          <el-option v-for="item in bigOptions" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="行业小类:">
        <el-select v-model="form.sshy" placeholder="请选择行业小类" clearable class="w-250" filterable>
          <el-option v-for="item in options" :key="item" :label="item" :value="item"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" type="primary" @click="save">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { ElMessage } from 'element-plus';
import { sshy, sshySave } from '@/api/automatic'
const emit = defineEmits(['success'])
const form = ref({
  comId: 0,
  sshyBig: "",
  sshy: ""
})
const dialogVisible = ref(false)
const bigOptions = ref([])
const options = ref([])
const sshyList = ref([])
const init = (id) => {
  form.value.comId = id
  sshy({ comId: id }).then(res => {
    if (res.data.msg == 'success') {
      bigOptions.value = res.data.data.sshyBigList
      sshyList.value = res.data.data.sshyList
      form.value.sshyBig = res.data.data.sshyBig
      if (form.value.sshyBig) {
        changeBig()
      }
      form.value.sshy = res.data.data.sshy
    }
  })
  dialogVisible.value = true
}
const save = () => {
  if (!form.value.sshyBig || !form.value.sshy) {
    ElMessage.warning('请选择行业大类和行业小类')
    return
  }
  sshySave(form.value).then(res => {
    if (res.data.msg == 'success') {
      ElMessage.success('保存成功')
      emit('success')
      dialogVisible.value = false
    }
  })
}
const changeBig = () => {
  form.value.sshy = ""
  const item = sshyList.value[form.value.sshyBig]
  options.value = item || []
}

defineExpose({ init })
</script>

<style scoped lang="less"></style>
