<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="自定义生成凭证" v-model="dialogFormVisibleSetting"  class="button_bg" width="40%">
      <!-- 进项 -->
    <div class="right_title">
      <el-form ref="form" :model="form" label-width="90px" label-position="left">
        <div style="float: right;margin: -35px 10px 0px 0px;">
          <el-button @click="showTemplateDialog" type="primary" plain size="small">自定义模板</el-button>
        </div>
        <!-- 进项 -->
        <div v-if="this.type == 'in'" style="margin-top: 15px;">
          <el-form-item label="凭证习惯">
            <el-radio-group v-model="mergeSetInfo.in">
              <el-radio :label='0'>按单张发票生成凭证</el-radio>
              <el-radio :label='1'>按相同往来单位合并凭证</el-radio>
              <el-radio :label='2'>按业务类型合并凭证</el-radio>
              <el-radio :label='3'>按时间合并凭证</el-radio>
              <el-radio :label='4'>按往来单位和时间合并凭证</el-radio>
              <el-radio :label='5'>按补充项目合并</el-radio>
              <div>
                <el-checkbox v-model="mergeSetInfo.inEntry" :true-label="1" :false-label="0">结算分录合并一条</el-checkbox>
                <el-checkbox v-model="mergeSetInfo.inEntry" :true-label="2" :false-label="0">合并分录(不合并往来单位)</el-checkbox>
              </div>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="摘要自定义">
            <el-input placeholder="请填写摘要内容" v-model="mergeSetInfo.remark" style="width:90%;margin-bottom:10px"></el-input>
            <el-checkbox-group v-model="mergeSetInfo.inSummaryArr">
              <el-checkbox label="默认业务类型" checked disabled></el-checkbox>
              <el-checkbox label="开票日期"></el-checkbox>
              <el-checkbox label="单位名称"></el-checkbox>
              <el-checkbox label="金额"></el-checkbox>
              <el-checkbox label="开票项目"></el-checkbox>
              <el-checkbox label="发票号码"></el-checkbox>
              <el-checkbox label="发票类型"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        
        <!-- 销项 -->
        <div v-if="this.type == 'out'" style="margin-top: 15px;">
          <el-form-item label="凭证习惯">
            <el-radio-group v-model="mergeSetInfo.out">
              <el-radio :label='0'>按单张发票生成凭证</el-radio>
              <el-radio :label='1'>按相同往来单位合并凭证</el-radio>
              <el-radio :label='2'>按业务类型合并凭证</el-radio>
              <el-radio :label='3'>按时间合并凭证</el-radio>
              <el-radio :label='4'>按往来单位和时间合并凭证</el-radio>
              <el-radio :label='5'>按补充项目合并</el-radio>
              <div>
                <el-checkbox v-model="mergeSetInfo.outEntry" :true-label="1" :false-label="0">结算分录合并一条</el-checkbox>
                <el-checkbox v-model="mergeSetInfo.outEntry" :true-label="2" :false-label="0">合并分录(不合并往来单位)</el-checkbox>

              </div>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="摘要自定义">
            <el-input placeholder="请填写摘要内容" v-model="mergeSetInfo.remark" style="width:90%;margin-bottom:10px"></el-input>
            <el-checkbox-group v-model="mergeSetInfo.outSummaryArr">
              <el-checkbox label="默认业务类型" checked disabled></el-checkbox>
              <el-checkbox label="开票日期"></el-checkbox>
              <el-checkbox label="单位名称"></el-checkbox>
              <el-checkbox label="金额"></el-checkbox>
              <el-checkbox label="开票项目"></el-checkbox>
              <el-checkbox label="发票号码"></el-checkbox>
              <el-checkbox label="发票类型"></el-checkbox>
              <el-checkbox label="是否代开"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        
        <!-- 银行的 -->
        <div v-if="this.type == 'bank'" style="margin-top: 15px;">
          <el-form-item label="凭证习惯">
            <el-radio-group v-model="mergeSetInfo.bank">
              <el-radio :label="0">单张生成</el-radio>
              <el-radio :label="1">合并生成 按业务类型（会计科目）</el-radio>
              <el-radio :label="3">合并生成 按业务类型（会计科目一级）</el-radio>
              <el-radio :label="2">合并生成 按时间</el-radio>
              <div>
                <el-checkbox v-model="mergeSetInfo.bankEntry" :true-label="1" :false-label="0">结算银行存款分录合并一条</el-checkbox>
                <el-checkbox v-model="mergeSetInfo.dealingsEntry" :true-label="1" :false-label="0">结算往来单位分录合并一条</el-checkbox>
              </div>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="摘要自定义">
            <el-input placeholder="请填写摘要内容" v-model="mergeSetInfo.remark" style="width:90%;margin-bottom:10px"></el-input>
            <el-checkbox-group v-model="mergeSetInfo.bankSummaryArr">
              <el-checkbox label="默认业务类型" checked></el-checkbox>
              <el-checkbox label="业务类型"></el-checkbox>
              <el-checkbox label="备注"></el-checkbox>
              <el-checkbox label="交易日期"></el-checkbox>
              <el-checkbox label="单位名称"></el-checkbox>
              <el-checkbox label="金额"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-form>
    </div>
      <template #footer>
        <el-button @click="addTemplate" type="primary" size="small">保存为模板</el-button>
        <el-button @click="mergeOk" type="primary" size="small">生成凭证</el-button>
        <el-button @click="dialogFormVisibleSetting = false" size="small">取消</el-button>
      </template>
  </el-dialog>
  <settingDialog ref="settingDialog"></settingDialog>

  <!-- 新增模板名称 -->
  <el-dialog
    :close-on-click-modal="false"
    v-model="dialogTemplateVisible"
    :title="templateTitle"
    width="400px"
    destroy-on-close
    :modal="xzmodel"
  >
    <el-form :model="temp">
      <el-form-item label="模板名称：" :label-width="formLabelWidth">
        <el-input
          v-model="temp.templateName"
          style="width: 200px"
          size="small"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeTemplate()" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="saveTemplate()" size="small">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 模板凭证弹出框 -->
  <el-dialog
    :close-on-click-modal="false"
    v-model="showTemplate"
    title="选择模板"
    width="500px"
    :modal="tcmodel"
    destroy-on-close
  >
  <el-scrollbar :maxHeight="400">
    <el-checkbox-group v-model="checkName" @change=handleCheckbox size="mini">
      <el-checkbox :label="tag.templateName" border v-for="tag in templateLits" :key="tag.id" @click="choose(tag)" style="margin-top: 10px;">
        {{ tag.templateName }}
        <el-icon @click.prevent="delTemplate(tag)" style="margin: 0px -10px 0px 3px;color: red;"><CloseBold /></el-icon>
      </el-checkbox>
    </el-checkbox-group>
  </el-scrollbar>
  <template #footer>
      <span class="dialog-footer">
        <el-button @click="showTemplate = false" size="small"
          >取消</el-button
        >
        <el-button type="primary" @click="qdTemplate()" v-if="templateLits.length>0" size="small">
          确定
        </el-button>
      </span>
    </template>
    
  </el-dialog>

</template>

<script>
import { oneKeyVoucher,saveTemplateName, delTemplateName,getTemplateName} from '@/api/voucher'

export default {
  name:'oneKeyVoucherHabbit',
  data() {
    return {
      dialogTemplateVisible:false,//新增模板弹出框
      dialogFormVisibleSetting:false,
      templateTitle:'',//新增模板名称dialog标题
      activeName: 'first',
      checked: true,
      checkName:[],
      tcmodel:true,
      xzmodel:true,
      mergeSetInfo: {
        id:1,
        in: 1,
        out: 1,
        bank: 1,
        timeStatus: 0,
        outSummaryArr: [],
        inSummaryArr: [],
        bankSummaryArr: [],
        bankInfo: [],
        inEntry: 1,
        outEntry: 1,
        createUserStatus: 0,
        examUserStatus: 0,
        templateName:'',
        remark:'',
      },
      value:'',
      templateLits:[],
      type:'in',//进销：in 销项：out 银行bank
      templateId:null,//模板id
      ids:undefined,
      showTemplate:false,
      temp:{
        templateName:'',
      },
      tempLateType: '',//区分新增还是修改模板名称
      templateInfo:{//保存模版数据
        timeStatus: 0,
        outSummaryArr: [],
        inSummaryArr: [],
        bankSummaryArr: [],
        bankInfo: [],
        inEntry: 1,
        outEntry: 1,
        createUserStatus: 0,
        examUserStatus: 0,
        templateName:'',
        remark:'',
      },
      templateInfoTemp:{},//修改存储模板名称数据
      sortField:'',
    }
  },
  methods:{
    getList(type,ids){
      this.type = type
      if(type == 'bank'){
        this.ids = ids.invoiceArray
        this.sortField = ids.sortField
        this.sortType = ids.sortType*1
      }else{
        this.ids = ids
      }
      this.cancel()
      this.dialogFormVisibleSetting = true
    },
    cancel(){
      this.mergeSetInfo= {
        id:1,
        in: 1,
        out: 1,
        bank: 1,
        timeStatus: 0,
        outSummaryArr: [],
        inSummaryArr: [],
        bankSummaryArr: [],
        bankInfo: [],
        inEntry: 1,
        outEntry: 1,
        createUserStatus: 0,
        examUserStatus: 0,
        templateName:'',
      }
    },
    // 选择进行赋值
    choose(val){
      this.templateId = val.id
    },
    // 实现单选
    handleCheckbox(val){
      if(val.length >1){
        this.$nextTick(() => {
          this.checkName=[val[val.length - 1]];
        })
      }
    },

    // 选择模板进行赋值
    qdTemplate(){
      getTemplateName({type:this.type,id:this.templateId}).then(res=>{
        if(res.data.msg == "success" ){
          this.mergeSetInfo = res.data.data.list[0]
          if(this.type == 'in'){
            this.mergeSetInfo.inSummaryArr=this.mergeSetInfo.inSummary.split(',')
          }else if(this.type =='out'){
            this.mergeSetInfo.outSummaryArr=this.mergeSetInfo.outSummary.split(',')
          }else{
            this.mergeSetInfo.bankSummaryArr=this.mergeSetInfo.bankSummary.split(',')
          }
          this.showTemplate = false
          this.templateId =''
        }
      })
    },

    // 新增模板名称弹出框
    addTemplate(){
      this.templateTitle ='新增自定义模板'
      this.dialogTemplateVisible = true
      this.tempLateType ='xz'
      this.temp.templateName =''
    },
    // 凭证模板弹出框
    showTemplateDialog(){
      this.checkName=[]
      getTemplateName({type:this.type}).then(res=>{
        if(res.data.msg == "success" ){
          this.templateLits = res.data.data.list
          this.showTemplate = true
        }
      })
    },
    // 删除模板名称
    delTemplate(value){
      this.tcmodel=false
      this.xzmodel=false
      this.$confirm('此操作将删除模板, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          delTemplateName(value).then(res=>{
            if(res.data.msg == "success"){
              this.$qzfMessage("删除成功")
              getTemplateName({type:this.type}).then(res1=>{
                if(res.data.msg == "success" ){
                  this.templateLits = res1.data.data.list
                  // 默认第一项
                  this.dialogTemplateVisible = false
                }
              })
            }
          })
        }).catch(() => {
          this.tcmodel=true
          this.xzmodel=true        
        });
    },
    // 保存模板名称
    saveTemplate(){
      // console.log(this.mergeSetInfo.remark);
      if(!this.temp.templateName){
        this.$qzfMessage("请填写模板名称",2)
        return
      }
      if(this.tempLateType=='xz'){
        this.templateInfo.type = this.type
        this.templateInfo.templateName = this.temp.templateName
      }else{
        this.templateInfo = this.templateInfoTemp
        this.templateInfo.templateName = this.temp.templateName
      }
      this.templateInfo.in = this.mergeSetInfo.in * 1
      this.templateInfo.out = this.mergeSetInfo.out * 1
      this.templateInfo.bank = this.mergeSetInfo.bank * 1
      this.templateInfo.inEntry = this.mergeSetInfo.inEntry * 1
      this.templateInfo.outEntry = this.mergeSetInfo.outEntry * 1
      this.templateInfo.bankEntry = this.mergeSetInfo.bankEntry * 1
      this.templateInfo.dealingsEntry = this.mergeSetInfo.dealingsEntry * 1
      this.templateInfo.remark = this.mergeSetInfo.remark
      if(this.type =='in'){
        this.templateInfo.inSummary = this.mergeSetInfo.inSummaryArr.join(',')
      }else if(this.type =='out'){
        this.templateInfo.outSummary = this.mergeSetInfo.outSummaryArr.join(',')
      }else{
        this.templateInfo.bankSummary = this.mergeSetInfo.bankSummaryArr.join(',')
      }
      console.log(this.templateInfo);
      saveTemplateName(this.templateInfo).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("生成模板成功")
          getTemplateName({type:this.type}).then(res1=>{
            if(res.data.msg == "success" ){
              this.templateLits = res1.data.data.list
              this.dialogTemplateVisible = false
            }
          })
        }
      })
    },
    closeTemplate(){
      this.dialogTemplateVisible = false
      if(this.templateLits){
        this.mergeSetInfo.templateName = this.templateLits[0].templateName
      }else{
        this.mergeSetInfo.templateName = ''
      }
    },
    mergeOk() {
      if(this.type == "bank"){
        this.$parent.isLoading = true
      }else{
        this.$parent.loadingBig = true
      }
      
      this.mergeSetInfo.in = this.mergeSetInfo.in * 1
      this.mergeSetInfo.out = this.mergeSetInfo.out * 1
      this.mergeSetInfo.bank = this.mergeSetInfo.bank * 1
      this.mergeSetInfo.inEntry = this.mergeSetInfo.inEntry * 1
      this.mergeSetInfo.outEntry = this.mergeSetInfo.outEntry * 1
      this.mergeSetInfo.bankEntry = this.mergeSetInfo.bankEntry * 1
      this.mergeSetInfo.dealingsEntry = this.mergeSetInfo.dealingsEntry * 1
      if(this.type =='in'){
        this.mergeSetInfo.inSummary = this.mergeSetInfo.inSummaryArr.join(',')
      }else if(this.type =='out'){
        this.mergeSetInfo.outSummary = this.mergeSetInfo.outSummaryArr.join(',')
      }else{
        this.mergeSetInfo.bankSummary = this.mergeSetInfo.bankSummaryArr.join(',')
      }
      let param = {
        type:this.type,
        ids:this.ids,
        merge:this.mergeSetInfo,
        sortField:this.sortField,
        sortType:this.sortType,
      }
      oneKeyVoucher(param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("凭证生成成功")
          this.$bus.emit('voucherUpdate')
          this.$emit("success")
        }else{
          let route = ''
          if(res.data.msg == '未设置进项税额科目' || res.data.msg == '未设置未认证进项税额科目' || res.data.msg == '未设置销项税额科目' || res.data.msg == '未设置费用现金结算科目' || res.data.msg == '未设置现金结算科目'){
            route = '/setting/habbitSettingBook'
            this.$refs.settingDialog.getInit(res.data.msg,route)
          }
          this.$emit("success")
        }
      })
      this.dialogFormVisibleSetting = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.right_title{
  width: 100%;
  margin: 0 auto;
}
.right_title h5{
  font-weight: normal;
}
.right_title p{
  font-size: 14px;
  color: red !important;
  text-align: left !important;
}
.item_radio,.one_item{
  width: 100%;
  padding-left: 30px;
  height: 25px;
}
.item_radio2{
  width: 100%;
  padding-left: 60px;
}
.item_lists{
  width: 100%;
  padding-left: 60px;
}
.el-radio-group{
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
  font-size: 0;
}
.none-border p {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #eee;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.showTemplateName{
  margin: -12px 0px 10px 0px;
  font-size: 15px;
}
.showTemplateName-item{
    margin: 8px;
}
:deep(.el-tag .el-tag__close){
  color: #f90707!important;
}
:deep(.el-checkbox){
  color: var(--themeColor,#17a2b8)!important;
  border-color: var(--themeColor,#17a2b8)!important;
}
:deep(.el-checkbox.is-bordered.is-checked){
  border-color: var(--themeColor,#17a2b8)!important;
}
:deep(.el-checkbox__inner){
  border-color: var(--themeColor,#17a2b8)!important;
}
</style>