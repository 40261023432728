<template>
  <el-checkbox-group size="small" @change="changeValue" v-model="ppStatus">
    <el-checkbox-button
      v-for="item in options"
      :key="item.value"
      :label="item.value"
      >{{ item.label }}</el-checkbox-button
    >
  </el-checkbox-group>
</template>
<script>
export default {
  name: "selectPpStatus",
  props: {
    ppStatus: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      options: [
        {
          value: 1,
          label: "采集成功",
        },
        {
          value: 6,
          label: "未采集",
        },
        {
          value: 99,
          label: '发起中',
        },
        {
          value: 2,
          label: "部分采集失败",
        },
        {
          value: 3,
          label: "排队中",
        },
		{
          value: 9,
          label: "采集中",
        },
		{
          value: 10,
          label: "跳过采集",
        },
        {
          value: 4,
          label: "采集失败",
        },
        {
          value: 7,
          label: "任务已撤销",
        },
      ],
      value: "",
    };
  },
  methods: {
    changeValue() {
      this.$emit("update:ppStatus", this.ppStatus);
      this.$emit("success", this.ppStatus);
    },
  },
};
</script>

<style></style>
